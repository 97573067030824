import { Commentary } from '@3fourteen/core';
import dayjs from 'dayjs';
import { useMediaQuery } from 'hooks/useMediaQuery';
import { memo, useMemo } from 'react';
import Skeleton from 'react-loading-skeleton';

import { PostIcon, PostTag } from 'components';
import Link from 'next/link';

import styles from './CommentaryItem.module.scss';

interface CommentaryItemProps {
  commentary: Commentary;
  useHref?: boolean;
  showExcerpt?: boolean;
  openInNewTab?: boolean;
}

function CommentaryItem({
  commentary,
  useHref = true,
  showExcerpt = true,
  openInNewTab,
}: CommentaryItemProps) {
  const isSmallScreen = useMediaQuery(768);

  const el = useMemo(() => {
    if (commentary) {
      const { title, date, excerpt, categorySlug, author } = commentary;
      const iconSize = isSmallScreen ? 14 : 16;

      const isRetro = categorySlug === 'retrospectives';
      const retroAuthorText = 'Warren & Fernando';

      const authorText = !isRetro ? author : retroAuthorText;
      const dateFormat = !isRetro ? 'MMMM D, YYYY' : 'MMM D, YYYY';

      return (
        <div className='min-w-0'>
          <div className='flex items-baseline mb-1'>
            <PostIcon categorySlug={categorySlug} size={iconSize} />
            <p className='pr-0.5 pl-1.5 mb-0 font-medium whitespace-nowrap font-serif text-ellipsis overflow-hidden'>
              {title}
            </p>
          </div>
          <div className='flex items-center mb-1'>
            <span
              className='whitespace-nowrap text-ellipsis overflow-hidden uppercase text-13 text-neutral-500'
              data-testid='author'>
              {authorText}
            </span>
            <span className='whitespace-nowrap overflow-hidden uppercase text-13 py-0 px-1.5 text-clip text-neutral-500'>
              |
            </span>
            <span
              className='whitespace-nowrap text-ellipsis overflow-hidden uppercase text-13 text-neutral-500'
              data-testid='report-date'>
              {dayjs(date).format(dateFormat)}
            </span>
            <div className='ml-2'>
              <PostTag category={categorySlug} />
            </div>
          </div>
          {showExcerpt && (
            <div className={styles.excerpt} dangerouslySetInnerHTML={{ __html: excerpt }} />
          )}
        </div>
      );
    }
  }, [commentary, isSmallScreen, showExcerpt]);

  if (!commentary) return <Skeleton count={showExcerpt ? 2 : 1} style={{ margin: '10px 0px' }} />;

  const { categorySlug, slug } = commentary;

  if (useHref) {
    return (
      <Link
        href={`/${categorySlug}/${slug}`}
        target={openInNewTab ? '_blank' : undefined}
        className='min-w-0'>
        {el}
      </Link>
    );
  }

  return el;
}
export default memo(CommentaryItem);
