export const routes = {
  login: '/login',
  home: '/',
  commentary: '/commentary',
  charts: {
    root: '/charts',
    catalog: '/charts/catalog',
  },
  models: {
    root: '/models',
    raa: '/models/real-asset-allocation-v1',
    RAA_V2_20: '/models/real-asset-allocation',
    RAA_V2_10: '/models/real-asset-allocation-simple',
    spRisk: '/models/sp-risk',
    yo: '/models/yield-optimizer',
    crude: '/models/crude',
    gold: '/models/gold',
    bitcoin: '/models/bitcoin',
    sector: '/models/sector-rotation',
  },
  stockSelection: {
    root: '/stock-selection',
    trendRisk: '/stock-selection/trend-risk',
    ourPicks: '/stock-selection/our-picks',
    fullCycleTrend: '/stock-selection/full-cycle-trend',
    fctm: '/stock-selection/full-cycle-trend-midcap',
  },
  portfolioBuilder: {
    root: '/portfolio-builder',
    portfolios: {
      root: '/portfolio-builder/portfolios',
      id: '/portfolio-builder/portfolios/[id]',
    },
    appendix: '/portfolio-builder/appendix',
    demo: '/portfolio-builder/demo',
  },
  research: {
    id: '/research/publications/real-asset-allocation-the-world-has-changed',
  },
  favorites: '/favorites',
  search: '/search',
  energy: '/energy',
  account: '/account',
  customResearch: '/custom-research',
  publications: {
    root: 'publications',
    stockSelection: '/publications/saving-us-from-ourselves-stock-selection-v1-0',
    raaDeepDive: '/publications/real-asset-allocation-adapt-to-thrive',
    h2Outlook: '/publications/h2-outlook-whistling-past-the-graveyard',
    crudeModel: '/publications/introducing-our-core-crude-oil-model',
    goldModel: '/publications/gold-modeling-systemic-trust',
    bitcoinModel: '/publications/bitcoin-crypto-fraud-or-the-future',
    yieldFamine: '/publications/the-great-yield-famine',
    fullCycleTrend: '/publications/stock-selection-for-a-new-market-regime',
    fctm: '/publications/the-cure-for-macro-uncertainty',
  },
  cookies: '/cookies',
  forgotPassword: '/forgot-password',
};
