import { onLogout } from 'services/auth';

import { routes } from './routes';

export type ParentItem = 'models' | 'stock-selection' | 'portfolio-builder' | 'charts';
export type IconFamily = 'fontisto' | 'simple-line' | 'ant-design';

interface Link {
  displayName: string;
  mobileDisplayName?: string;
  route?: string;
  parent?: ParentItem;
  icon?: string;
  onClick?: () => void;
  childOf?: ParentItem;
  iconFamily?: IconFamily;
  isMobileNavItem?: boolean;
}

// DON'T CHANGE THIS ORDER!
export const navLinks: Link[] = [
  {
    displayName: 'Home',
    route: routes.home,
    icon: 'home',
    isMobileNavItem: true,
  },
  {
    displayName: 'Commentary',
    route: routes.commentary,
    icon: 'book-open',
    isMobileNavItem: true,
  },

  {
    displayName: 'Models',
    parent: 'models',
    icon: 'graph',
    route: routes.models.root,
    isMobileNavItem: true,
  },
  {
    displayName: 'RAA',
    route: routes.models.RAA_V2_20,
    childOf: 'models',
  },
  {
    displayName: 'S&P risk',
    route: routes.models.spRisk,
    childOf: 'models',
  },
  {
    displayName: 'Sector Rotation',
    route: routes.models.sector,
    childOf: 'models',
  },
  {
    displayName: 'Crude',
    route: routes.models.crude,
    childOf: 'models',
  },
  {
    displayName: 'Gold',
    route: routes.models.gold,
    childOf: 'models',
  },
  // {
  //   displayName: 'Bitcoin',
  //   route: routes.models.bitcoin,
  //   childOf: 'models',
  // },
  // {
  //   displayName: 'Yield Optimizer',
  //   route: routes.models.yo,
  //   childOf: 'models',
  // },
  {
    displayName: 'Stock Selection',
    mobileDisplayName: 'Stocks',
    parent: 'stock-selection',
    icon: 'areachart',
    iconFamily: 'ant-design',
    route: routes.stockSelection.root,
    isMobileNavItem: true,
  },
  {
    displayName: 'Full-Cycle Trend',
    route: routes.stockSelection.fullCycleTrend,
    childOf: 'stock-selection',
  },
  {
    displayName: 'Full-Cycle Trend Midcap',
    route: routes.stockSelection.fctm,
    childOf: 'stock-selection',
  },
  {
    displayName: 'Our Picks',
    route: routes.stockSelection.ourPicks,
    childOf: 'stock-selection',
  },
  // {
  //   displayName: 'Trend Risk',
  //   route: routes.stockSelection.trendRisk,
  //   childOf: 'stock-selection',
  // },
  {
    displayName: 'Energy',
    route: routes.energy,
    icon: 'energy',
    isMobileNavItem: true,
  },
  {
    displayName: 'Portfolio Builder',
    parent: 'portfolio-builder',
    route: routes.portfolioBuilder.root,
    isMobileNavItem: false,
    icon: 'wrench',
  },
  {
    displayName: 'My Portfolios',
    route: routes.portfolioBuilder.portfolios.root,
    childOf: 'portfolio-builder',
  },
  {
    displayName: 'Charts',
    parent: 'charts',
    route: routes.charts.root,
    icon: 'chart',
    isMobileNavItem: true,
  },
  {
    displayName: 'Catalog',
    route: routes.charts.catalog,
    childOf: 'charts',
  },
  {
    displayName: 'Favorites',
    route: routes.favorites,
    iconFamily: 'ant-design',
    icon: 'heart',
    isMobileNavItem: true,
  },
  {
    displayName: 'Search',
    route: routes.search,
    icon: 'magnifier',
    isMobileNavItem: true,
  },
];

export const accountLinks: Link[] = [
  {
    displayName: 'Account',
    route: routes.account,
    icon: 'user',
  },
  {
    displayName: 'Logout',
    icon: 'logout',
    onClick: onLogout,
  },
  {
    displayName: 'Support',
    icon: 'customerservice',
    iconFamily: 'ant-design',
    route: 'mailto:dev@3fourteenresearch.com?subject=Support Request from app.3fr',
  },
];
